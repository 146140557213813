type FlushablePromise<Value> = Promise<Value> & { flush: () => Promise<Value> };

function throttleable<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value>,
  delay: 0,
  { type }: { type: "async" },
): (...args: ArgsType) => FlushablePromise<Value>;

function throttleable<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value> | Value,
  delay: number,
  { leading, type }: { leading?: boolean; type: "debounce" | "throttle" },
): (...args: ArgsType) => FlushablePromise<Value>;

/*
  Throttles calls in various ways, returns a promise that resolves whenever the delay has passed
 */
function throttleable<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value> | Value,
  delay: number,
  {
    leading = false,
    type,
  }: { leading?: boolean; type: "async" | "debounce" | "throttle" },
): (...args: ArgsType) => FlushablePromise<Value> {
  const nextCall = {
    delayTimer: undefined as ReturnType<typeof setTimeout> | undefined,
    args: null as ArgsType | null,
    promise: null as FlushablePromise<Value> | null,
    resolve: null as ((value: Promise<Value> | Value) => void) | null,
    reject: null as ((error: unknown) => void) | null,
  };

  function clearNextCall() {
    nextCall.promise = null;
    nextCall.resolve = null;
    nextCall.reject = null;
  }

  function callCallback(args: ArgsType) {
    const result = callback(...args);
    nextCall.args = null;
    return Promise.resolve(result);
  }

  const resolveNextCall = () => {
    const resolve = nextCall.resolve;
    const reject = nextCall.reject;
    clearNextCall();
    if (!nextCall.args || !resolve || !reject) {
      return;
    }

    try {
      resolve(callCallback(nextCall.args));
    } catch (error) {
      reject(error);
    }
  };

  function toFlushablePromise(
    promise: Promise<Value>,
  ): FlushablePromise<Value> {
    (promise as FlushablePromise<Value>).flush = () => {
      clearTimeout(nextCall.delayTimer);
      resolveNextCall();
      return promise;
    };
    return promise as FlushablePromise<Value>;
  }

  return (...args: ArgsType) => {
    nextCall.args = args;

    if (type === "debounce") {
      clearTimeout(nextCall.delayTimer);
      nextCall.delayTimer = setTimeout(() => resolveNextCall(), delay);
    }

    // As long as a nextCall is queued return it. It will use the latest args when it gets called
    // When it is called it will clear the promise so that a new call can be queued below, restarting the chain
    if (nextCall.promise) {
      return nextCall.promise;
    }

    if (type === "throttle") {
      nextCall.delayTimer = setTimeout(() => resolveNextCall(), delay);
    }

    nextCall.promise = toFlushablePromise(
      new Promise((resolve, reject) => {
        nextCall.resolve = resolve;
        nextCall.reject = reject;
      }),
    );

    if (leading || type === "async") {
      const result = toFlushablePromise(callCallback(nextCall.args));
      if (type === "async") {
        void result.then(resolveNextCall);
      }
      return result;
    }

    return nextCall.promise;
  };
}

function debounce<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value> | Value,
  delay: number,
  options: { leading?: boolean } = {},
) {
  return throttleable(callback, delay, { ...options, type: "debounce" });
}

function throttle<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value> | Value,
  delay: number,
  options: { leading?: boolean } = {},
) {
  return throttleable(callback, delay, { ...options, type: "throttle" });
}

// Uses the promise of the callback as a throttle delay. This basically disables concurrency
function asyncThrottle<ArgsType extends unknown[], Value>(
  callback: (...args: ArgsType) => Promise<Value>,
) {
  return throttleable(callback, 0, { type: "async" });
}

export { asyncThrottle, debounce, throttle };
