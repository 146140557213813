const emailDomainsGeneral = Object.freeze([
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "outlook.com",
  "icloud.com",
  "msn.com",
]);

const emailDomainsNlNL = Object.freeze([
  ...emailDomainsGeneral,
  "ziggo.nl",
  "casema.nl",
  "tele2.nl",
  "kpn.nl",
  "kpnplanet.nl",
  "kpnmail.com",
  "kpnmail.nl",
  "zonnet.nl",
  "zeelandnet.nl",
  "upcmail.nl",
  "planet.nl",
  "outlook.nl",
  "live.nl",
  "icloud.nl",
  "caiway.nl",
  "hetnet.nl",
  "home.nl",
]);

const emailDomainsBE = Object.freeze([
  ...emailDomainsGeneral,
  "telenet.be",
  "skynet.be",
  "pandora.be",
  "live.be",
]);

const emailDomainsFrFR = Object.freeze([
  ...emailDomainsGeneral,
  "hotmail.fr",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "live.fr",
]);

export const emailDomains = {
  "nl-NL": emailDomainsNlNL,
  "nl-BE": emailDomainsBE,
  "fr-BE": emailDomainsBE,
  "fr-FR": emailDomainsFrFR,
};
