import { toRef } from "@vueuse/core";
import { defineStore } from "pinia";
import { watch } from "vue";

import { useFormStore } from "@/plugins/store/form";
import { useGtmStore } from "@/plugins/store/gtm";

/*
  Stores data about the stepper and navigation between steps
 */
export const useStepperStore = defineStore("stepper", {
  state: () => ({
    currentStep: 0,
  }),
  getters: {
    currentFormStep({ currentStep }) {
      return useFormStore().steps[currentStep]!;
    },
    nextStep: ({ currentStep }) => {
      if (useFormStore().steps.length === currentStep + 1) {
        return undefined;
      }
      return currentStep + 1;
    },
    previousStep: ({ currentStep }) => {
      if (currentStep === 0) {
        return undefined;
      }
      return currentStep - 1;
    },
  },
  actions: {
    goToStep(index: number) {
      const gtmStore = useGtmStore();
      const previousGtmStepIndex = gtmStore.gtmStepIndex;

      this.currentStep = index;

      void gtmStore.goToStepEvent({ previousGtmStepIndex });
      void gtmStore.formViewEvent();
    },
    goToNextStep() {
      this.goToStep(this.currentStep + 1);
    },
    goToPreviousStep() {
      this.goToStep(this.currentStep - 1);
    },
    initStoreWatchers() {
      watch(
        toRef(() => useFormStore().steps),
        () => {
          this.currentStep = 0;
        },
      );
    },
  },
});
