import type { SnakeCasedProperties } from "type-fest";

import type { ExtractArray } from "@/lib/helpers/types";

function keysToSnakeCase<
  ObjectType extends {
    [K in keyof ObjectType]: ObjectType[K];
  },
>(object: ObjectType) {
  const snakeCaseEntries = Object.entries(object).map(
    ([key, value]): [string, unknown] => [
      key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
      value,
    ],
  );
  return Object.fromEntries(
    snakeCaseEntries,
  ) as SnakeCasedProperties<ObjectType>;
}

function awaitTimeout(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function arrayWrap<Value>(value: Value) {
  if (Array.isArray(value)) {
    return value as ExtractArray<Value>;
  }
  return [value] as Exclude<
    Exclude<Value, unknown[] | readonly unknown[]>[],
    never[]
  >;
}

export { arrayWrap, awaitTimeout, keysToSnakeCase };
