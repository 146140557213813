import { i18nInstance } from "@solvari/common-fe/composables";
import {
  emitComponentLoaded,
  getSiteLocale,
  initOnPrefill,
} from "@solvari/common-fe/helpers";
import { getSentry } from "@solvari/common-fe/integrations";
import { createPinia } from "pinia";
import { createApp, reactive } from "vue";

import { initPrimeVueThemed } from "@solvari/components";

import FormRoot from "@/components/FormRoot.vue";
import { initEnv } from "@/helpers/env";
import { getProps, getRootElement } from "@/helpers/rootElement";
import { initSentry } from "@/plugins/sentry";

function main() {
  initEnv();
  initSentry();

  const urlSearchParams = new URLSearchParams(window.location.search);

  const urlPrefills = {
    zipcode: urlSearchParams.get("zip_code"),
    houseNumber: urlSearchParams.get("house_nr"),
    city: urlSearchParams.get("city"),
    street: urlSearchParams.get("street"),
  };

  const rootElement = getRootElement();
  const datasetProps = getProps(rootElement);

  const props = reactive({
    locale: getSiteLocale(),
    ...urlPrefills,
    ...datasetProps,
  });

  initOnPrefill(props);

  const app = createApp(FormRoot, props);
  getSentry().trackVueErrors(app);

  app.use(i18nInstance());
  app.use(createPinia());
  initPrimeVueThemed(app);

  app.mount(rootElement);

  emitComponentLoaded("solvari-form");
}

main();
