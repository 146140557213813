import type { Ref } from "vue";

import { computed } from "vue";

function useElementValue<
  Value extends boolean | number | string | null | undefined,
>(value: Ref<Value>) {
  return computed(() => {
    return typeof value.value === "boolean"
      ? String(value.value)
      : ((value.value as Exclude<Value, boolean>) ?? undefined);
  });
}

export { useElementValue };
