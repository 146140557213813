import { memoize } from "@solvari/common-fe/helpers";
import Pusher from "pusher-js";

import { getEnv } from "@/helpers/env";

export const usePusher = memoize(
  () =>
    new Pusher(getEnv().integrations.pusher.key, {
      cluster: getEnv().integrations.pusher.cluster,
    }),
);
