import type { LocaleIso } from "@solvari/common-fe/helpers";

import {
  captureAbTestingException,
  getAbTestFeature,
} from "@solvari/common-fe/ab-testing";
import { getSiteLocale } from "@solvari/common-fe/helpers";
import { defineStore } from "pinia";

/*
  Stores data about running ab tests
 */
export const useAbTestsStore = defineStore("abTests", {
  getters: {
    exitIntent: () => getAbTestFeature<boolean>("forms-exit-intent"),
    reviewStars: () => getAbTestFeature<boolean>("forms-review-stars"),
    crossSell: () => getAbTestFeature<boolean>("forms-cross-sell"),
    translations() {
      return getAbTestFeature<Record<string, unknown>>("forms-translations");
    },
    formVariantId() {
      return getAbTestFeature<Record<number, number>>("form-variant-id");
    },
    formGroupVariantId() {
      return getAbTestFeature<
        Record<number, Partial<Record<LocaleIso, number>>>
      >("form-group-variant-id");
    },
    formVariantName() {
      return getAbTestFeature<Record<number, string>>("form-variant-name");
    },
    formGroupVariantName() {
      return getAbTestFeature<Record<number, string>>(
        "form-group-variant-name",
      );
    },
  },
  actions: {
    getFormVariantId(formId: number | null) {
      if (!formId || !this.formVariantId) {
        return null;
      }

      const variantId = this.formVariantId[formId];
      if (!variantId) {
        captureAbTestingException(
          new Error(
            `Tried to run form-variant-id but no variantId was found for formId ${formId}`,
          ),
        );
        return null;
      }

      return variantId;
    },
    getFormGroupVariantId(formGroupId: number | null) {
      if (!formGroupId || !this.formGroupVariantId) {
        return null;
      }

      const variantId = this.formGroupVariantId[formGroupId]?.[getSiteLocale()];
      if (!variantId) {
        captureAbTestingException(
          new Error(
            `Tried to run form-group-variant-id but no variantId was found for formGroupId ${formGroupId}`,
          ),
        );
        return null;
      }

      return variantId;
    },
    getFormVariantName(formId: number | null) {
      if (!formId || !this.formVariantName) {
        return null;
      }

      const variantId = this.formVariantName[formId];
      if (!variantId) {
        captureAbTestingException(
          new Error(
            `Tried to run form-variant-name but no variantName was found for formId ${formId}`,
          ),
        );
        return null;
      }

      return variantId;
    },
    getFormGroupVariantName(formGroupId: number | null) {
      if (!formGroupId || !this.formGroupVariantName) {
        return null;
      }

      const variantId = this.formGroupVariantName[formGroupId];
      if (!variantId) {
        captureAbTestingException(
          new Error(
            `Tried to run form-group-variant-name but no variantName was found for formGroupId ${formGroupId}`,
          ),
        );
        return null;
      }

      return variantId;
    },
    getFormId(formId: number | null, formGroupId: number | null) {
      return (
        this.getFormVariantId(formId) ??
        this.getFormGroupVariantId(formGroupId) ??
        formId
      );
    },
    getFormGroupId(formGroupId: number | null) {
      return this.getFormGroupVariantId(formGroupId) ? null : formGroupId;
    },
    getVariantName(formId: number | null, formGroupId: number | null) {
      return (
        this.getFormVariantName(formId) ??
        this.getFormGroupVariantName(formGroupId)
      );
    },
  },
});
