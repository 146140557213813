import { definePreset } from "@primevue/themes";
import Lara from "@primevue/themes/lara";

const solvariPreset: unknown = definePreset(Lara, {
  semantic: {
    primary: {
      50: "var(--primary-50)",
      100: "var(--primary-100)",
      200: "var(--primary-200)",
      300: "var(--primary-300)",
      400: "var(--primary-400)",
      500: "var(--primary-500)",
      600: "var(--primary-600)",
      700: "var(--primary-700)",
      800: "var(--primary-800)",
      900: "var(--primary-900)",
    },
    success: {
      50: "var(--success-50)",
      100: "var(--success-100)",
      200: "var(--success-200)",
      300: "var(--success-300)",
      400: "var(--success-400)",
      500: "var(--success-500)",
      600: "var(--success-600)",
      700: "var(--success-700)",
      800: "var(--success-800)",
      900: "var(--success-900)",
    },
    warning: {
      50: "var(--warning-50)",
      100: "var(--warning-100)",
      200: "var(--warning-200)",
      300: "var(--warning-300)",
      400: "var(--warning-400)",
      500: "var(--warning-500)",
      600: "var(--warning-600)",
      700: "var(--warning-700)",
      800: "var(--warning-800)",
      900: "var(--warning-900)",
    },
    danger: {
      50: "var(--danger-50)",
      100: "var(--danger-100)",
      200: "var(--danger-200)",
      300: "var(--danger-300)",
      400: "var(--danger-400)",
      500: "var(--danger-500)",
      600: "var(--danger-600)",
      700: "var(--danger-700)",
      800: "var(--danger-800)",
      900: "var(--danger-900)",
    },
    gray: {
      50: "var(--gray-50)",
      100: "var(--gray-100)",
      200: "var(--gray-200)",
      300: "var(--gray-300)",
      400: "var(--gray-400)",
      500: "var(--gray-500)",
      600: "var(--gray-600)",
      700: "var(--gray-700)",
      800: "var(--gray-800)",
      900: "var(--gray-900)",
    },
    colorScheme: {
      light: {
        primary: {
          color: "{primary.700}",
          contrastColor: "#ffffff",
          hoverColor: "{primary.600}",
          activeColor: "{primary.500}",
        },
      },
    },
  },
  components: {
    button: {
      colorScheme: {
        light: {
          primary: {
            background: "{primary.700}",
            focusShadow: "{primary.100}",
            borderColor: "{primary.700}",
            hoverBorderColor: "{primary.600}",
            hoverBackground: "{primary.600}",
            activeBorderColor: "{primary.500}",
            activeBackground: "{primary.500}",
          },
          secondary: {
            background: "{success.500}",
            color: "#ffffff",
            hoverColor: "#ffffff",
            activeColor: "#ffffff",
            focusShadow: "{success.100}",
            borderColor: "{success.500}",
            hoverBorderColor: "{success.600}",
            hoverBackground: "{success.600}",
            activeBorderColor: "{success.700}",
            activeBackground: "{success.700}",
          },
          success: {
            background: "{success.500}",
            focusShadow: "{success.100}",
            borderColor: "{success.500}",
            hoverBorderColor: "{success.600}",
            hoverBackground: "{success.600}",
            activeBorderColor: "{success.700}",
            activeBackground: "{success.700}",
          },
          warn: {
            background: "{warning.500}",
            focusShadow: "{warning.100}",
            borderColor: "{warning.500}",
            hoverBorderColor: "{warning.600}",
            hoverBackground: "{warning.600}",
            activeBorderColor: "{warning.700}",
            activeBackground: "{warning.700}",
          },
          danger: {
            background: "{danger.500}",
            focusShadow: "{danger.100}",
            borderColor: "{danger.500}",
            hoverBorderColor: "{danger.600}",
            hoverBackground: "{danger.600}",
            activeBorderColor: "{danger.700}",
            activeBackground: "{danger.700}",
          },
          contrast: {
            background: "{gray.500}",
            focusShadow: "{gray.100}",
            borderColor: "{gray.500}",
            hoverBorderColor: "{gray.600}",
            hoverBackground: "{gray.600}",
            activeBorderColor: "{gray.700}",
            activeBackground: "{gray.700}",
          },
        },
      },
    },
    galleria: {
      borderWidth: "0",
    },
  },
});

export default solvariPreset;
