import { onClickOutside, toRef, useEventListener } from "@vueuse/core";
import { ref, watchEffect } from "vue";

import { useFeatureFlagStore } from "@/plugins/store/featureFlags";
import { useMetaStore } from "@/plugins/store/meta";

function useHighlightOnFocus(
  highlightOnFocus = useFeatureFlagStore().highlightOnFocus,
  element = toRef(() => useMetaStore().rootElement),
  isVisible = toRef(() => useMetaStore().isVisible),
) {
  const highlighted = ref(false);

  if (!highlightOnFocus) {
    return highlighted;
  }

  useEventListener(element, "click", () => {
    highlighted.value = true;
  });

  onClickOutside(element, () => {
    highlighted.value = false;
  });

  watchEffect(() => {
    if (!isVisible.value) {
      highlighted.value = false;
    }
  });

  return highlighted;
}

export { useHighlightOnFocus };
