import type { Decimals } from "@/lib/components/logic/atoms/input/props";

import { replaceIfPrecededBy } from "@/lib/helpers/regex";

function filterNonNumericChars(value: string, decimals = true): string {
  if (decimals) {
    value = value.replace(",", ".").replace(/[^\d.-]+/g, "");
    value = replaceIfPrecededBy(value, /\./, /.*\..*/, "");
  } else {
    value = value.replace(/[^\d-]+/g, "");
  }
  value = replaceIfPrecededBy(value, "-", /./, "");
  return value;
}

function isAtDecimalsLimit(
  value: number | string,
  decimals: Decimals,
): boolean {
  const stringValue = String(value);

  if (decimals === true) {
    return false;
  }

  const valueDecimals = stringValue.split(".", 2);
  return !!valueDecimals[1] && valueDecimals[1].length > Number(decimals);
}

function roundDecimals(value: number | string, decimals: Decimals): string {
  if (!isAtDecimalsLimit(value, decimals)) {
    return String(value);
  }

  return Number(value).toFixed(Number(decimals));
}

function truncateDecimals(
  value: number | string,
  decimalsLimit: Decimals,
): string {
  const stringValue = String(value);

  if (!isAtDecimalsLimit(value, decimalsLimit)) {
    return stringValue;
  }

  const [integers, decimals] = stringValue.split(".", 2);
  if (!integers) {
    return "";
  }
  const truncatedDecimals = decimals?.slice(0, Number(decimalsLimit));
  return !truncatedDecimals ? integers : `${integers}.${truncatedDecimals}`;
}

export { filterNonNumericChars, roundDecimals, truncateDecimals };
