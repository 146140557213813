import type {
  Decimals,
  PrimitiveOrArrayValue,
} from "@/lib/components/logic/atoms/input/props";

import { truncateDecimals } from "@/lib/helpers/numbers";
import { arrayWrap } from "@/lib/helpers/utils";
import { eager } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules/defineRule";

const number = defineRule({
  name: "number",
  validate: (value: unknown) => {
    return arrayWrap(value).every((value) => !isNaN(Number(value)));
  },
  events: eager,
});

const decimals = defineRule({
  name: "decimals",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: Decimals,
  ) => {
    if (!value) {
      return true;
    }
    return arrayWrap(value).every(
      (value) =>
        typeof value !== "boolean" &&
        Number(truncateDecimals(value, params)) === Number(value),
    );
  },
  events: eager,
});

const min = defineRule({
  name: "min",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number,
  ) => {
    value = value || 0;
    return arrayWrap(value).every(
      (value) => typeof value !== "boolean" && Number(value) >= params,
    );
  },
});

const max = defineRule({
  name: "max",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number,
  ) => {
    value = value || 0;
    return arrayWrap(value).every(
      (value) => typeof value !== "boolean" && Number(value) <= params,
    );
  },
});

export { decimals, max, min, number };
