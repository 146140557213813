import { isString } from "radash";

import {
  autocompleteNlNlAddress,
  getZipcodeCitySuggestions,
} from "@/lib/api/address.api";
import { zipcodeRegex } from "@/lib/enums/zipcodeRegex";
import { type LocaleIso, localeToIso } from "@/lib/helpers/locales";
import { alphabeticCompare } from "@/lib/helpers/strings";
import { onCreatedIfValue } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules/defineRule";

const zipcode = defineRule({
  name: "zipcode",
  validate: (value: string | null | undefined, locale: LocaleIso) => {
    const localeIso = localeToIso(locale);
    if (!localeIso || !value) {
      return false;
    }
    return zipcodeRegex[localeIso].test(value.toString());
  },
});

const zipcodeCityNlBeExistsRule = defineRule({
  name: "exists",
  validate: async (zipcodeCity: unknown, locale: LocaleIso) => {
    if (!zipcodeCity || typeof zipcodeCity !== "string") {
      return false;
    }
    const { zipcode, city } = splitZipcodeCity(zipcodeCity);

    const suggestions = await getZipcodeCitySuggestions({
      city,
      locale,
      zipcode,
    });

    return !!suggestions.find(
      (suggestion) =>
        suggestion.zipcode === zipcode ||
        alphabeticCompare(suggestion.city, city),
    );
  },
  events: onCreatedIfValue(["blur"]),
  component: "manualToggle",
  color: "warning",
});

function splitZipcodeCity(zipcodeCity: string) {
  const zipcodeCityWords = zipcodeCity.split(" ");

  const zipcode =
    zipcodeCityWords.find((zipcodeCityPart) =>
      /^\d+[a-z]*$/i.test(zipcodeCityPart),
    ) || "";
  const city = zipcodeCityWords
    .filter((zipcodeCityPart) => {
      return zipcodeCityPart !== "-" && /^\D+$/iu.test(zipcodeCityPart);
    })
    .join(" ");

  return { zipcode, city };
}

async function zipcodeNlNlExists(value: number | string | null | undefined) {
  if (!isString(value) || !zipcode("nl-NL").validate(value)) {
    return false;
  }
  const autocompleteResult = await autocompleteNlNlAddress({
    zipcode: value.replace(" ", "").toUpperCase(),
  });
  return !!autocompleteResult && !(autocompleteResult instanceof Error);
}

const zipcodeExistsRule = defineRule({
  name: "exists",
  validate: async (
    value: number | string | null | undefined,
    locale: "fr-BE" | "nl-BE" | "nl-NL",
  ) => {
    if (!isString(value)) {
      return false;
    }
    if (locale === "nl-NL") {
      return await zipcodeNlNlExists(value);
    }
    return await zipcodeCityNlBeExistsRule(locale).validate(value);
  },
  events: onCreatedIfValue(["blur"]),
  color: "warning",
  blocking: false,
});

export {
  splitZipcodeCity,
  zipcode,
  zipcodeCityNlBeExistsRule,
  zipcodeExistsRule,
};
