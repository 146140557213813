import { toReactive } from "@vueuse/core";
import { omit } from "radash";
import { reactive, toRef } from "vue";

import type { UseCheckboxRadioEmit } from "@/lib/components/logic/atoms/useCheckboxRadio";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import * as useCheckboxRadio from "@/lib/components/logic/atoms/useCheckboxRadio";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import { useDescribedBy } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useSubtext.scoped,
  ...useValidationProviderScoped<boolean>(),
  ...omit(useCheckboxRadio.props, ["value"]),
  modelValue: { type: Boolean, default: false },
});

const emits = emitsDefinition([
  ...useCheckboxRadio.emits,
  ...useValidationProviderEmits,
]);

type UseCheckboxProps = DefineProps<typeof props>;
type UseCheckboxEmit = UseCheckboxRadioEmit & UseValidationProviderEmits;

function use(props: UseCheckboxProps, emit: UseCheckboxEmit) {
  const { label, tooltip, subtext } = useAutoI18n(
    toRef(() => props.name),
    reactivePick(props, ["label", "tooltip", "subtext"]),
  );

  const { describedBy, ids } = useDescribedBy(reactive({ subtext }));

  const { error, errorComponent, errorProps, validationListeners } =
    useValidationProvider<boolean>(props, emit);

  const checkboxRadio = {
    props: mergeReactive(
      pickProps(props, omit(useCheckboxRadio.props, ["value"])),
      {
        tooltip,
        describedBy,
        value: toRef(() => props.name),
        type: "checkbox",
        label,
      },
    ),
    on: mergeListeners(
      reEmit(emit, useCheckboxRadio.emits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtext.vIf(reactive({ subtext })),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    checked: toRef(() => props.modelValue),
    checkboxRadio,
    error,
    errorComponent,
    errorProps,
    subtextAtom,
  };
}

export type { UseCheckboxEmit, UseCheckboxProps };
export { emits, props, use };
