import type { Ref } from "vue";

import { onBeforeUnmount, ref, watch } from "vue";

export function useElementSize(target: Ref<HTMLElement | null | undefined>) {
  const observer = ref<ResizeObserver | null>(null);
  const animationFrame = ref<number | null>(null);

  const width = ref(0);
  const height = ref(0);
  const contentWidth = ref(0);
  const contentHeight = ref(0);

  function update([entry]: ResizeObserverEntry[]) {
    if (!entry) {
      return;
    }

    animationFrame.value = window.requestAnimationFrame(() => {
      width.value = (entry.target as HTMLElement).offsetWidth;
      height.value = (entry.target as HTMLElement).offsetHeight;
      contentWidth.value = entry.contentRect.width;
      contentHeight.value = entry.contentRect.height;
    });
  }

  function reset() {
    width.value = 0;
    height.value = 0;
    contentWidth.value = 0;
    contentHeight.value = 0;
  }

  function useFallback(target: HTMLElement) {
    width.value = target.offsetWidth;
    height.value = target.offsetHeight;

    const { paddingTop, paddingBottom, paddingLeft, paddingRight } =
      getComputedStyle(target);
    contentWidth.value =
      target.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);
    contentHeight.value =
      target.clientHeight - parseFloat(paddingTop) - parseFloat(paddingBottom);
  }

  watch(target, (newTarget, oldTarget) => {
    if (observer.value && oldTarget) {
      observer.value.disconnect();
    }

    if (!newTarget) {
      reset();
      return;
    }

    if (!("ResizeObserver" in window)) {
      useFallback(newTarget);
      return;
    }

    if (!observer.value) {
      observer.value = new ResizeObserver(update);
    }

    observer.value.observe(newTarget);
  });

  onBeforeUnmount(() => {
    if (animationFrame.value) {
      window.cancelAnimationFrame(animationFrame.value);
    }
    observer.value?.disconnect();
  });

  return {
    width,
    height,
    contentWidth,
    contentHeight,
  };
}
