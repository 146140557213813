import { type LocaleIso, memoize } from "@solvari/common-fe/helpers";
import { asyncThrottle } from "@solvari/common-fe/helpers";
import { zipcode as zipcodeRule } from "@solvari/common-fe/validation";
import ky from "ky";

import { getEnv } from "@/helpers/env";

const getAddressMeta = asyncThrottle(
  memoize(
    async ({
      locale,
      zipcode,
      houseNumber,
      city,
      street,
    }: {
      city: string;
      houseNumber: string;
      locale: LocaleIso;
      street: string;
      zipcode: string;
    }) => {
      // This should match backend validation
      if (
        !(
          ["nl-NL", "nl-BE", "fr-BE"].includes(locale) &&
          houseNumber.trim() &&
          zipcodeRule(locale).validate(zipcode) &&
          (locale === "nl-NL" || (city.trim() && street.trim()))
        )
      ) {
        return null;
      }

      const response = await ky
        .get(`${getEnv().network.api.base}/system/v3/geo/meta`, {
          searchParams: {
            locale,
            zipcode,
            houseNumber,
            city,
            street,
          },
        })
        .json<{ constructionYear: number; surface: number }>();
      if (!Object.keys(response).length) {
        return null;
      }
      return response;
    },
  ),
);

export { getAddressMeta };
