import type {
  MaybeComputedElementRef,
  UseIntersectionObserverOptions,
} from "@vueuse/core";

import { useIntersectionObserver } from "@vueuse/core";
import { ref } from "vue";

export function useIsVisible(
  target: MaybeComputedElementRef,
  options: UseIntersectionObserverOptions = {},
  callback?: IntersectionObserverCallback,
) {
  const isVisible = ref(false);
  useIntersectionObserver(
    target,
    (entries, observer) => {
      isVisible.value = entries[0]!.isIntersecting;
      callback?.(entries, observer);
    },
    options,
  );

  return isVisible;
}
