function alphanumericCompare(string1: string, string2: string) {
  return (
    string1.replace(/[^0-9a-z]+$/gi, "").toLowerCase() ===
    string2.replace(/[^0-9a-z]+$/gi, "").toLowerCase()
  );
}

function alphabeticCompare(string1: string, string2: string) {
  return (
    string1.replace(/[^a-z]+$/gi, "").toLowerCase() ===
    string2.replace(/[^a-z]+$/gi, "").toLowerCase()
  );
}

export { alphabeticCompare, alphanumericCompare };
