import { arrayWrap } from "@solvari/common-fe/helpers";
import { defineRule } from "@solvari/common-fe/validation";
import { intersects } from "radash";

const blocker = defineRule({
  name: "blocker",
  validate: (modelValue: unknown, items: Record<number | string, string>) => {
    if (!modelValue && modelValue !== 0) {
      return true;
    }

    const stringModelValues = arrayWrap(modelValue).map(String);
    return !intersects(stringModelValues, Object.keys(items));
  },
  message: (_fieldLabel, modelValue, _fieldName, items) => {
    if (!modelValue && modelValue !== 0) {
      return "";
    }

    const stringModelValues = arrayWrap(modelValue).map(String);

    const key = Object.keys(items).find((key) =>
      stringModelValues.includes(key),
    )!;
    return items[key]!;
  },
  component: "alert",
});

export { blocker };
