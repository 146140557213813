import { toReactive } from "@vueuse/core";
import { nanoid } from "nanoid";
import { computed, reactive, toRef } from "vue";

import type { UseInputAtomEmit } from "@/lib/components/logic/atoms/input/useInputAtom";
import type { DefineProps } from "@/lib/composables/componentComposable";
import type { UseValidationProviderEmits } from "@/lib/validation";

import {
  decimals as decimalsProp,
  type as typeProp,
} from "@/lib/components/logic/atoms/input/props";
import * as useInputAtom from "@/lib/components/logic/atoms/input/useInputAtom";
import * as useCharacterCounter from "@/lib/components/logic/atoms/useCharacterCounter";
import * as useDescription from "@/lib/components/logic/atoms/useDescription";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import { useDescribedBy, useModel } from "@/lib/composables";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
  reEmit,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useI18n";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import {
  decimals,
  email,
  max,
  maxLength,
  min,
  minLength,
  number,
  patternRule,
  telephone,
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation";

const inferrableRulesProps = propsDefinition({
  type: typeProp,
  pattern: { type: RegExp, required: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  decimals: decimalsProp,
  max: { type: Number, required: false },
  min: { type: Number, required: false },
});

const props = propsDefinition({
  ...useValidationProviderScoped<number | string | null | undefined>(),
  ...useDescription.scoped,
  ...useLabel.scoped,
  ...useTooltip.scoped,
  ...useCharacterCounter.scoped,
  ...useSubtext.scoped,
  ...useInputAtom.scoped,
  ...inferrableRulesProps,
});

const emits = emitsDefinition([
  "update:loading",
  ...useInputAtom.emits,
  ...useValidationProviderEmits,
]);

type UseTextInputProps = DefineProps<typeof props>;
type UseTextInputEmit = UseInputAtomEmit &
  UseValidationProviderEmits &
  ((event: "update:loading", value: UseTextInputProps["loading"]) => void);
type InferrableRulesProps = DefineProps<typeof inferrableRulesProps>;

function inferTextInputRules(props: Partial<InferrableRulesProps>) {
  function getInferredTextRules(
    textProps: Partial<Pick<InferrableRulesProps, "maxlength" | "minlength">>,
  ) {
    const rules = [];

    if (textProps.maxlength || textProps.maxlength === 0) {
      rules.push(maxLength(textProps.maxlength));
    }

    if (textProps.minlength || textProps.minlength === 0) {
      rules.push(minLength(textProps.minlength));
    }

    return rules;
  }

  function getInferredNumberRules(
    numberProps: Partial<
      Pick<InferrableRulesProps, "decimals" | "max" | "min">
    >,
  ) {
    const rules = [];

    rules.unshift(number());
    rules.unshift(decimals(numberProps.decimals ?? false));

    if (numberProps.max || numberProps.max === 0) {
      rules.unshift(max(numberProps.max));
    }

    if (numberProps.min || numberProps.min === 0) {
      rules.unshift(min(numberProps.min));
    }

    return rules;
  }

  return computed(() => {
    const rules = [];
    if (props.type === "email") {
      rules.push(email());
    }
    if (props.type === "tel") {
      rules.push(telephone());
    }

    if (props.type === "number") {
      rules.push(...getInferredNumberRules(props));
    } else {
      rules.push(...getInferredTextRules(props));
    }

    if (props.pattern) {
      rules.push(patternRule(props.pattern));
    }

    return rules;
  });
}

function use(props: UseTextInputProps, emit: UseTextInputEmit) {
  const loading = useModel("loading", props, emit, { local: true });

  const { label, tooltip, description, subtext, errorLabel } = useAutoI18n(
    toRef(() => props.name),
    reactivePick(props, [
      "label",
      "tooltip",
      "description",
      "subtext",
      "errorLabel",
    ]),
  );

  const id = nanoid(10);
  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  const { validationListeners, error, errorComponent, errorProps, inputProps } =
    useValidationProvider<number | string | null | undefined>(
      mergeReactive(props, {
        loading,
        errorLabel,
        rules: [...inferTextInputRules(props).value, ...props.rules],
      }),
      emit,
    );

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabel.scoped), { for: id, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltip.scoped), {
      tooltipId: toRef(() => ids.tooltip),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescription.vIf(reactive({ description })),
    props: mergeReactive(pickProps(props, useDescription.scoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const inputAtom = {
    props: mergeReactive(pickProps(props, useInputAtom.scoped), inputProps, {
      id,
      describedBy,
    }),
    on: mergeListeners(
      reEmit(emit, useInputAtom.emits),
      toReactive(validationListeners),
    ),
  };

  const subtextAtom = {
    if: useSubtext.vIf(reactive({ subtext })),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  const characterCounterAtom = {
    if: useCharacterCounter.vIf(props),
    props: pickProps(props, useCharacterCounter.scoped),
  };

  return {
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    inputAtom,
    characterCounterAtom,
    subtextAtom,
    error,
    errorComponent,
    errorProps,
  };
}

export type { UseTextInputEmit, UseTextInputProps };
export { emits, inferrableRulesProps, inferTextInputRules, props, use };
