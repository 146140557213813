import type { SIconDefinition } from "@/lib/icons/fontAwesome.types";

const faSolvariCredit: SIconDefinition = {
  prefix: "fab",
  iconName: "solvari-credit",
  icon: [
    16,
    16,
    [],
    "e001",
    "M8,0C3.58,0,0,3.58,0,8s3.58,8,8,8,8-3.58,8-8S12.42,0,8,0Zm0,14.6c-3.65,0-6.6-2.95-6.6-6.6S4.35,1.4,8,1.4s6.6,2.95,6.6,6.6-2.95,6.6-6.6,6.6Zm1.45-4.97c.05,.12,.07,.26,.07,.41,0,.19-.05,.36-.15,.52-.09,.15-.24,.27-.44,.36-.2,.09-.45,.13-.75,.13-.25,0-.48-.03-.69-.08-.21-.05-.39-.13-.54-.25-.15-.11-.26-.26-.35-.45s-.12-.41-.12-.67h-1.76c0,.48,.1,.9,.3,1.25,.2,.35,.46,.64,.8,.87,.34,.23,.71,.4,1.12,.51,.41,.11,.83,.16,1.25,.16,.47,0,.89-.05,1.27-.16,.38-.11,.71-.26,.98-.46,.27-.2,.48-.45,.63-.74,.14-.29,.22-.62,.22-1,0-.35-.06-.67-.18-.94-.12-.28-.3-.53-.54-.74-.24-.22-.54-.42-.89-.59-.35-.18-.76-.33-1.22-.47-.28-.09-.52-.17-.73-.26-.21-.09-.39-.19-.53-.29-.14-.1-.25-.21-.33-.33-.07-.12-.11-.25-.11-.39,0-.19,.05-.36,.15-.52,.1-.16,.25-.28,.46-.37,.2-.09,.46-.14,.76-.14s.58,.05,.79,.16c.21,.11,.37,.26,.47,.45,.11,.19,.16,.41,.16,.66h1.75c0-.51-.13-.96-.39-1.35-.26-.39-.62-.71-1.09-.93-.47-.23-1.02-.34-1.65-.34-.46,0-.88,.06-1.27,.17-.38,.11-.71,.28-1,.49-.28,.21-.5,.46-.66,.75-.16,.29-.23,.61-.23,.97s.07,.69,.22,.97c.15,.28,.36,.52,.62,.73,.27,.21,.57,.39,.91,.54,.35,.16,.71,.3,1.1,.42,.35,.11,.63,.21,.85,.32,.22,.1,.39,.21,.51,.31,.12,.1,.2,.21,.25,.34Z",
  ],
};

export default faSolvariCredit;
