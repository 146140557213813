function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function toRegExpPart(value: RegExp | string) {
  return typeof value === "string" ? escapeRegExp(value) : value.source;
}

function replaceIfPrecededBy(
  text: string,
  searchValue: RegExp | string,
  precededBy: RegExp | string,
  replaceValue: string,
) {
  return text.replace(
    new RegExp(
      `(${toRegExpPart(precededBy)})${toRegExpPart(searchValue)}`,
      "g",
    ),
    (_match, p1: string) => `${p1}${replaceValue}`,
  );
}

export { escapeRegExp, replaceIfPrecededBy };
