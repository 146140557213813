import type { LocaleIso } from "@solvari/common-fe/helpers";
import type { DeepReadonly } from "vue";
import type { LocaleMessageObject } from "vue-i18n";

import { withKyErrors } from "@solvari/common-fe/integrations";
import ky from "ky";
import { shake } from "radash";

import type { FormStep } from "@/helpers/types";

import { getEnv } from "@/helpers/env";

type FormData = {
  formId: number;
  locale: LocaleIso;
  name: string;
  revisionId: number;
  steps: FormStep[];
} & ({ product: { id: number; name: string } } | { productId: number });

type FormRequestResource = DeepReadonly<{
  availableLocales?: LocaleIso[];
  data: FormData;
  translations: LocaleMessageObject;
}>;

const fetchFormByGroup = withKyErrors(
  async (id: number, locale: LocaleIso, formVariantName?: string) => {
    return await ky
      .get(`${getEnv().network.api.base}/system/v1/form/${id}/${locale}`, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        searchParams: shake({ "variant-name": formVariantName }),
        retry: 10,
        timeout: 30000,
      })
      .json<FormRequestResource>();
  },
);

const fetchFormById = withKyErrors(
  async (id: number, formVariantName?: string, revisionId?: number | null) => {
    let url = `${getEnv().network.api.base}/system/v1/form/${id}`;
    if (revisionId) {
      url = `${url}/${revisionId}`;
    }

    return await ky
      .get(url, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        searchParams: shake({ "variant-name": formVariantName }),
        retry: 10,
        timeout: 30000,
      })
      .json<FormRequestResource>();
  },
);

export { fetchFormByGroup, fetchFormById };
export type { FormRequestResource };
