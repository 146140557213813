import type { HTTPError, TimeoutError } from "ky";

import { withAsyncErrors } from "@/lib/helpers/errors";

const withKyErrors: <Args extends unknown[], Return>(
  callback: (...args: Args) => Promise<Return>,
) => (...args: Args) => Promise<HTTPError | Return | TimeoutError | TypeError> =
  withAsyncErrors;

export { withKyErrors };
