import type { MergeDeep, Simplify } from "type-fest";

import { isObject, unique } from "radash";

function mergeDeepValue(object1Value: unknown, object2Value: unknown): unknown {
  if (object2Value === undefined) {
    return object1Value;
  }
  if (!isObject(object1Value) || !isObject(object2Value)) {
    return object2Value;
  }

  const keys = unique([
    ...Object.keys(object1Value),
    ...Object.keys(object2Value),
  ]);
  return Object.fromEntries(
    keys.map((key) => {
      return [
        key,
        mergeDeepValue(
          (object1Value as Record<string, unknown>)[key],
          (object2Value as Record<string, unknown>)[key],
        ),
      ];
    }),
  );
}

function mergeDeep<
  Object1 extends Record<PropertyKey, unknown>,
  Object2 extends Record<PropertyKey, unknown>,
>(object1: Object1, object2: Object2) {
  return mergeDeepValue(object1, object2) as Simplify<
    MergeDeep<Object1, Object2>
  >;
}

export { mergeDeep };
