import { defineRule } from "@/lib/validation/rules/defineRule";

const minAmount = defineRule({
  name: "minAmount",
  validate: (value: unknown[] | null | undefined, min: number | string) => {
    if (!value) {
      value = [];
    }
    return value.length >= Number(min);
  },
});

const maxAmount = defineRule({
  name: "maxAmount",
  validate: (value: unknown[] | null | undefined, max: number | string) => {
    if (!value) {
      value = [];
    }
    return value.length <= Number(max);
  },
});

export { maxAmount, minAmount };
