import type { LocaleFormats, LocaleIso } from "@/lib/helpers/locales";

export const localeFormatsMap = Object.freeze([
  {
    iso: "nl-NL",
    document: "nl",
    morpheus: "nl-NL",
    country: "NL",
    language: "nl",
  },
  {
    iso: "nl-BE",
    document: "nl-BE",
    morpheus: "be-NL",
    country: "BE",
    language: "nl",
  },
  {
    iso: "fr-BE",
    document: "fr-BE",
    morpheus: "be-FR",
    country: "BE",
    language: "fr",
  },
  {
    iso: "fr-FR",
    document: "fr",
    morpheus: "fr-FR",
    country: "FR",
    language: "fr",
  },
] satisfies LocaleFormats[]);

export const alternateLocales = Object.freeze({
  "nl-NL": "nl-BE",
  "nl-BE": "nl-NL",
  "fr-BE": "fr-FR",
  "fr-FR": "fr-BE",
} satisfies Record<LocaleIso, LocaleIso>);
