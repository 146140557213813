function withErrors<Args extends unknown[], Return>(
  callback: (...args: Args) => Return,
) {
  return (...args: Args) => {
    try {
      return callback(...args);
    } catch (error) {
      return error as Error;
    }
  };
}

function withAsyncErrors<Args extends unknown[], Return>(
  callback: (...args: Args) => Promise<Return>,
) {
  return async (...args: Args) => {
    try {
      return await callback(...args);
    } catch (error) {
      return error as Error;
    }
  };
}

export { withAsyncErrors, withErrors };
